import axios from 'axios'

export const UPDATE_OUT_OF_STOCK_FORM = "UPDATE_OUT_OF_STOCK_FORM";
export const SET_ALERT = "SET_ALERT";
export const SET_LOADER = "SET_LOADER";
export const SET_ACTION = "SET_ACTION";
export const SET_API_RESPONSE = "SET_API_RESPONSE";
export const SET_API_PRODUCTS_RESPONSE = "SET_API_PRODUCTS_RESPONSE";
export const SET_SORTED_API_RESPONSE = "SET_SORTED_API_RESPONSE";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_TAB = "SET_TAB";
export const UPDATE_THEME = "UPDATE_THEME";
export const SET_MAP_DETAILS = "SET_MAP_DETAILS"

export const setAlert = (data) => {
    return {
        type: SET_ALERT,
        payload: data
    }
}

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    }
}

export const setAction = (data) => {
    return {
        type: SET_ACTION,
        payload: data
    }
}

export const setApiResponse = (data) => {
    return {
        type: SET_API_RESPONSE,
        payload: data
    }
}

export const setApiProducts = (data) => {
    return {
        type: SET_API_PRODUCTS_RESPONSE,
        payload: data
    }
}

export const setMapDetails = (data) => {
    return {
        type: SET_MAP_DETAILS,
        payload: data
    }
}

export const setSortedApiResponse = (data) => {
    return {
        type: SET_SORTED_API_RESPONSE,
        payload: data
    }
}

export const setUserLocation = (data) => {
    return {
        type: SET_USER_LOCATION,
        payload: data
    }
}

export const setTab = (data) => {
    return {
        type: SET_TAB,
        payload: data
    }
}

export const updateTheme = (data) => {
    return {
        type: UPDATE_THEME,
        payload: data
    }
}

export const updateOutOfStockForm = (data) => {
    return {
        type: UPDATE_OUT_OF_STOCK_FORM,
        payload: data
    }
}

export const getApiProducts = () => {
    return (dispatch) => {
        try {
            const brand = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : '';
            axios.get(`${process.env.REACT_APP_API}MapProducts?brand=${brand}`, {
                headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
            })
                .then(resp => {
                    dispatch(setApiProducts(resp.data))
                })
                .catch(error => {
                    console.log(error)
                })
        }
        catch (e) {
            console.log(e)
        }
    }
}


export const getMapDetails = () => {
    return (dispatch) => {
        try {
            const brand = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : '';
            axios.get(`${process.env.REACT_APP_API}mapDetails?brand=${brand}`, {
                headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
            })
                .then(resp => {
                    dispatch(setMapDetails(resp.data));
                })
                .catch(error => {
                    console.log(error)
                })
        }
        catch (e) {
            console.log(e)
        }
    }
}

export const submitQueryForm = (payload) => {
    return (dispatch) => {
        try {
            axios.post(`${process.env.REACT_APP_API}Email/Send`, payload, {
                headers: { 'Content-Type': 'multipart/form-data', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
            })
                .then(resp => {
                    console.log(resp)
                })
                .catch(error => {
                    console.log(error)
                })
        }
        catch (e) {
            console.log(e)
        }
    }
}
