import React, { useEffect } from "react";
import AppRoutes from "./AppRoutes";
import {useDispatch} from "react-redux";
import {updateTheme} from "./actions/mainAction";
import axios from 'axios'
import { useParams } from "react-router-dom";
import './App.css'

function App() {
  const dispatch = useDispatch()
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });


  return (
    <div className="App-Routes">
        <AppRoutes />
    </div>
  );
}

export default App;
