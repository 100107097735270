import {
    SET_ALERT,
    SET_LOADER,
    SET_ACTION,
    SET_API_RESPONSE,
    SET_SORTED_API_RESPONSE,
    SET_USER_LOCATION,
    SET_TAB,
    UPDATE_THEME,
    UPDATE_OUT_OF_STOCK_FORM,
    SET_API_PRODUCTS_RESPONSE,
    SET_MAP_DETAILS,
} from "../actions/mainAction";

const initialState = {
    alerts: {
        message: null,
        type: null,
    },
    loader: false,
    action: { status: null, item: null },
    apiResponse: [],
    apiProducts: [],
    mapDetails: {
        provinces: [],
        cities: [],
        categories: []
    },
    sortedApiResponse: {
        province: [],
        city: [],
        product: [],
        brand: [],
        category: []
    },
    currentLocation: {},
    tab: { id: null, detail: null },
    theme: {},
    outOfStockForm: [
        {
            name: "firstName",
            placeholder: "*First Name",
            value: "",
            type: "text",
            required: true
        },
        {
            name: "lastName",
            placeholder: "*Last Name",
            value: "",
            type: "text",
            required: true
        },
        {
            name: "email",
            placeholder: "*Email",
            value: "",
            type: "email",
            required: true
        },
        {
            name: "message",
            placeholder: "Message",
            value: "",
            type: "textarea",
            required: false
        }
    ]
};

const main = (state = initialState, action) => {
    const { type, payload } = action
    let newState = JSON.parse(JSON.stringify(state))

    switch (type) {
        case SET_ALERT:
            newState.alerts.message = payload.message
            newState.alerts.type = payload.type
            return newState
        case SET_LOADER:
            newState.loader = payload
            return newState
        case SET_ACTION:
            newState.action.status = payload.status
            newState.action.item = payload.item
            return newState
        case SET_API_RESPONSE:
            newState.apiResponse = payload
            return newState
        case SET_API_PRODUCTS_RESPONSE:
            newState.apiProducts = payload
            return newState
        case SET_MAP_DETAILS:
            newState.mapDetails = payload
            return newState
        case SET_SORTED_API_RESPONSE:
            newState.sortedApiResponse = payload
            return newState
        case SET_USER_LOCATION:
            newState.currentLocation = payload
            return newState
        case SET_TAB:
            newState.tab = payload
            return newState
        case UPDATE_THEME:
            newState.theme = payload
            return newState
        case UPDATE_OUT_OF_STOCK_FORM:
            newState.outOfStockForm = payload
            return newState
        default:
            return state
    }
};

export default main;
