export const LOCATION_CONSTANT = [
    {
        id: "location",
        name: "Location",
        expand: "expandable",
        data: [
            {
                id: "province",
                name: "Province",
                expand: "expandable",
                dependable: true,
                option: []
            },
            {
                id: "city",
                name: "City",
                expand: "expandable",
                selected: [],
                dataSelection: "province",
                option: []
            },
        ]
    }
]

export const PRODUCTS_CONSTANT = [
    {
        id: "products",
        name: "Products",
        expand: "expandable",
        data: [
            {
                id: "category",
                name: "Category",
                expand: "expandable",
                dependable: true,
                childFilter: "brand",
                option: []
            },
            {
                id: "brand",
                name: "Brand",
                expand: "expandable",
                dependable: true,
                selected: [],
                dataSelection: "category",
                childFilter: "product",
                option: []
            },
            {
                id: "product",
                name: "Product",
                expand: "expandable",
                dependable: true,
                selected: [],
                dataSelection: "brand",
                option: []
            }
        ]
    }
]

export const MENU_CONTENT = [
    {
        category: "refine-search",
        id: "search",
        name: "Search SKU",
        type: "search",
        value: ""
    },
    {
        category: "refine-search",
        id: "location",
        name: "Location",
        data: [],
        type: "radio",
        value: false
    },
    {
        category: "refine-search",
        id: "city",
        name: "City",
        data: [],
        type: "radio",
        value: false
    },
    {
        category: "refine-search",
        id: "products",
        name: "Products",
        data: [],
        type: "radio",
        value: false
    },
    {
        category: "refine-search",
        id: "category",
        name: "Category",
        data: [],
        type: "radio",
        value: ""
    },
]

export const OUT_OF_STOCK_QUERY = [
    {
        name: "firstName",
        placeholder: "*First Name",
        value: "",
        type: "text",
        required: true
    },
    {
        name: "lastName",
        placeholder: "*Last Name",
        value: "",
        type: "text",
        required: true
    },
    {
        name: "email",
        placeholder: "*Email",
        value: "",
        type: "email",
        required: true
    },
    {
        name: "message",
        placeholder: "Message",
        value: "",
        type: "textarea",
        required: false
    }
]
