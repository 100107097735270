import React from 'react'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MapPage from "./Components/MapPage";

export default function AppRoutes() {

    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/:customerKey" element={<MapPage />}/>
                    <Route exact path="/" element={<MapPage />}/>
                    <Route exact path='/:id' element={<MapPage />} />
                </Routes>
            </Router>
        </>
    )
}
