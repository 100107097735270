import * as React from 'react';
import { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import './appBar.css'
import { Avatar, Chip } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterSidebar from "./FilterSidebar";
import LocationDetail from "./LocationDetail";
import { setLoader, setTab } from "../../actions/mainAction";
import { getFilteredData, getSingleLocationData, setFilterResults } from "../../actions/filterAction";
import Tooltip from "@mui/material/Tooltip";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router";

const drawerWidth = 295;


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavBarContainer = {
    display: 'inline-flex',
    padding: "0 !important",
    paddingTop: "10px !important",
    fontWeight: 'bold'
};

const menuOverFlow = "200px"


export default function NavBar() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState("")
    const [selectedFilters, setSelectedFilters] = React.useState([])
    const [suggestions, setSuggestions] = React.useState([])
    const [allSuggestions, setAllSuggestions] = React.useState([])
    const [suggestionVisible, setSuggestionVisible] = React.useState(false)

    const { filterResults } = useSelector(state => state.filters)
    const { currentLocation, tab, action , apiProducts} = useSelector(state => state.main)
    const themePallet = useSelector(state => state.main.theme)
    const { lat, lng } = currentLocation;
    const customerKey = localStorage.getItem('customerKey', customerKey);
    const handleDrawer = () => {
        setOpen(!open);
    };

    const handleDelete = (item) => {
        dispatch(setLoader(true));

        if(new URL(window.location.href).searchParams.get('storeName')){
            navigate("/");
        }
        if(new URL(window.location.href).searchParams.get('productName')){
            navigate(`/?customerKey=${encodeURIComponent(customerKey)}`);
        }

        let updateSelectedFilter = selectedFilters.filter(filter => {
            if (filter.name !== item.name)
                return filter
        })
        setSelectedFilters(updateSelectedFilter);
        const { lat, lng } = currentLocation;
        let query = lat && lng ? `longlat=${lng},${lat}` : 'longlat=0,0';
        updateSelectedFilter.forEach((fltr) => {
            query = query ? query + `&${fltr.id}_id=${fltr.name}` : `${fltr.id}_id=${fltr.name}`
        });
        dispatch(getFilteredData(query));
    };

    useEffect(() => {
        if (tab.id && tab.detail)
            setOpen(!isMobile)
        if (tab.id === 'close-panel' && tab.detail === 'close-panel' && isMobile) {
            setOpen(false)
            dispatch(setTab({ id: null, detail: null }))
        }
    }, [tab])


    useEffect(() => {
      setOpen(!isMobile);
    }, []);

    useEffect(() => {
        if (action.status === "delete-filter-tag")
            handleDelete(action.item)
    }, [action])

    const getSuggestions = (e) => {
        setValue(e.target.value)
        if (e.target.value.length >= 3) {
            setSuggestionVisible(true)
            let response = []
            response = allSuggestions.filter(suggestion => {
                if (suggestion.name.toLowerCase().includes(e.target.value.toLowerCase()))
                    return suggestion
            })
            setSuggestions(response)
        } else {
            setSuggestionVisible(false)
            suggestions.length > 0 && setSuggestions([])
        }
    }

    const handleSetInputValue = (item) => {
        setValue(item.name)
        if (item.name !== "") {
            dispatch(setLoader(true))
            if(item.type === "store"){

                dispatch(getSingleLocationData(`longlat=${currentLocation?.lng ?? 0},${currentLocation?.lat ?? 0}&location=${encodeURIComponent(item.name)}`))
            }
            else{
                let query = `longlat=${lat ? lat : 0 },${lng? lng : 0}&product_id=${item.name}`
                setSelectedFilters([item])
                navigate(`/?productName=${encodeURIComponent(item.name)}`);
                dispatch(getFilteredData(query));
            }
            setSuggestionVisible(false)
        }
    }

    useEffect(() => {
        let options = []
        {filterResults && filterResults?.forEach(info => {
            options.push({ name: info.name, id: info , type : "store" })
        })}
        // apiProducts.forEach(info => {
        //     options.push({name: info.name, id: info})
        // })
        const updatedOptions = [
            ...new Map(options.map((item) => [item.name, item])).values(),
            ...apiProducts
        ];
        setAllSuggestions(updatedOptions)
    }, [filterResults])
    
    const clientName = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_CLIENT_NAME : '';

    return (
        <Box sx={{ display: 'block' }}>
            <AppBar position="fixed" style={{ height: "64px !important" }} sx={{ backgroundColor: themePallet.lightBackground }} >
                <div className="upper-nav" style={{ color: themePallet.darkFont, borderBottom: "4px solid " + themePallet.darkBackground }}>
                    <div className="img-logo">
                        <div className="avatar">
                            <Avatar className="avatar-profile" style={{ border: "1px solid " + themePallet.active, background: themePallet.active }}>
                                <PersonIcon />
                            </Avatar>
                        </div>
                        <div className="avatar-username" style={{ color: themePallet.active }}>{clientName}</div>
                    </div>
                </div>

                <div className="lower-nav" style={{ borderBottom: "4px solid " + themePallet.darkBackground }}>
                    <div className="sidebar-toggle" style={{ display: "flex", width: open && !isMobile ? "305px" : (open && isMobile) && "260%" }}>
                        <div className="nav-search" style={{ display: !open ? "none" : "block" }}>
                            <input type="text" placeholder="Search" id="search-input" value={value} className="search-field"
                                onChange={getSuggestions}
                                onBlurCapture={() => {
                                    setSuggestionVisible(false)
                                }}
                                onFocus={() => {
                                    setValue("")
                                }}
                                style={{ background: themePallet.darkBackground, color: themePallet.lightFont, border: "2px solid " + themePallet.darkBackground }}
                                autoComplete="off"
                            />
                            {
                                suggestionVisible && (
                                    <div className="suggestion" id="suggestion" style={{ background: themePallet.darkBackground, color: themePallet.lightFont }}>
                                        <div className="menu">
                                            {
                                                suggestions.map((item, index) => {
                                                    return (
                                                        <div id={"menu-item-" + index}
                                                            className="menu-item"
                                                            onMouseDown={() => handleSetInputValue(item)}
                                                            key={index}
                                                        >
                                                            <Tooltip
                                                                title={item.name}
                                                                placement="right"
                                                                arrow
                                                            >
                                                                <span>{item.name}</span>
                                                            </Tooltip></div>
                                                    )
                                                })
                                            }
                                            {
                                                suggestions.length === 0 && <div
                                                    className="menu-item"
                                                >
                                                    <span>No location found.</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="collapse-icon" style={{ background: themePallet.darkBackground, color: themePallet.active }}>
                            {open ? <KeyboardArrowLeftIcon button onClick={handleDrawer} /> :
                                <KeyboardArrowRightIcon button onClick={handleDrawer} />}
                        </div>
                    </div>
                    <div className="selected-filter-nav">
                        {selectedFilters.length > 0 && <span className="filter-tag" style={{ color: themePallet.darkFont }}>Filters</span>}
                        {
                            selectedFilters.length > 0 && selectedFilters.map(item => {
                                return (
                                    <Chip
                                        label={item.name}
                                        onDelete={() => handleDelete(item)}
                                        sx={{ bgColor: "#0C202B", color: themePallet.darkFont, ml: 1 }}
                                        deleteIcon={<CloseIcon
                                            style={{ color: themePallet.darkFont, width: 15, height: 15, background: themePallet.darkBackground }} />}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </AppBar>
            <Drawer variant="permanent" open={open} style={{ height: '100%' }}
                className={!open ? "custom-drawer" : "close-drawer"}
                sx={{ background: themePallet.darkBackground }}
            >
                <div className="sidebar-container" style={{ background: themePallet.lightBackground, borderRight: "5px solid " + themePallet.darkBackground }}>
                    {tab?.id === 'location-detail' ?
                        <LocationDetail />
                        :
                        <FilterSidebar setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />
                    }
                </div>
            </Drawer>
        </Box>
    );
}
