import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@mui/icons-material/Close";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getFilteredData,
    getSingleLocationData
} from "../../actions/filterAction";
import { setAction, setLoader } from "../../actions/mainAction";
import LocationIconLight from "../../images/location-target-light.png";
import LocationIconPremium5 from "../../images/location-target-premium5.png";
import LocationIconPureFarma from "../../images/location-target-pure-farma.png";
import LocationIcongcollectiveproject from "../../images/location-target-collectiveproject.png";
import LocationIconwoodynelson from "../../images/location-target-woodynelson.png";
import LocationIcon from "../../images/location-target.png";
import LocationIconghost from "../../images/location-target-ghost.png";
import { getUniqueObjectFromArray } from "../../utils";
import { useNavigate } from "react-router";

const imageSelection = {
    "farma-neobi": LocationIconPureFarma,
    "white-neobi": LocationIconLight,
    "blue-neobi": LocationIcon,
    "purple-neobi": LocationIconPremium5,
    "ghost-neobi": LocationIconghost,
    "collectiveproject": LocationIcongcollectiveproject,
    "woodynelson": LocationIconwoodynelson,

}

const FilterSidebar = ({ setSelectedFilters, selectedFilters }) => {
    const dispatch = useDispatch()
    const [expanded, setExpanded] = useState(false);
    const [menuExpanded, setMenuExpanded] = useState(false);
    const { filterResults, selectedFilter } = useSelector(state => state.filters)
    const { mapDetails, apiProducts, currentLocation } = useSelector(state => state.main)
    const themePallet = useSelector(state => state.main.theme)
    const [brands, setBrands] = useState([])
    const params = useParams();
    const navigate = useNavigate();
    const brand = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : '';

    useEffect(() => {
        setBrands(brand?.split(','));
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    const handleSubMenuChange = (panel) => (event, isExpanded) => {
        setMenuExpanded(isExpanded ? panel : false);
    }

    const handleFilter = (id, name) => {
        dispatch(setLoader(true))
        const uniqueFilters = [...getUniqueObjectFromArray([{ id, name }, ...selectedFilters], "id")]
        setSelectedFilters(uniqueFilters)
        const { lat, lng } = currentLocation;
        let query = lat && lng ? `longlat=${lng},${lat}` : 'longlat=0,0';
        uniqueFilters.forEach((fltr) => {
            query = query ? query + `&${fltr.id}_id=${fltr.name}` : `${fltr.id}_id=${fltr.name}`
        });

        if(id === 'product'){
            navigate(`/?productName=${encodeURIComponent(name)}`);
        }

        dispatch(getFilteredData(query));
    }

    const handleDetailPage = (item) => {
        dispatch(setLoader(true))
        dispatch(getSingleLocationData(`longlat=${currentLocation?.lng ?? 0},${currentLocation?.lat ?? 0}&location=${encodeURIComponent(item.name)}`))
    }

    const handleNearMeLocation = () => {
        dispatch(setAction({ status: "near-me", item: "near-me" }))
    }

    const handleDeleteAction = (item) => {
        dispatch(setAction({ status: "delete-filter-tag", item: item }))
    }
    const isSelected = (name) => {
        return selectedFilters.filter((filter) => filter.name === name).length > 0 ? true : false;
    }

    const getAccordionData = (id) => {
        let data = [];
        switch (id) {
            case 'province':
                data = mapDetails['provinces']
                break;

            case 'city':
                data = mapDetails['cities']
                break;

            case 'category':
                data = mapDetails['categories']
                break;

            case 'brand':
                data = brands
                break;

            case 'product':
                data = apiProducts
                break;

            default:
                break;
        }
        return data;
    }

    const getFilter = (id) => {
        return selectedFilter.filter((fltr) => fltr.id === id);
    }
    useEffect(() => {
        const paramsID = params.id;
        const productName = new URL(window.location.href).searchParams.get('productName');
        if(paramsID) {
            handleFilter('product', paramsID)
        } else if(productName) {
            handleFilter('product', productName);
            setMenuExpanded('product');
        }
    }, [])

    useEffect(() => {
        if(new URL(window.location.href).searchParams.get('storeName')){
            if(filterResults.filter(item => item.name.toLowerCase() === new URL(window.location.href).searchParams.get('storeName').toLowerCase()).length){
                handleDetailPage(filterResults.filter(item => item.name.toLowerCase() === new URL(window.location.href).searchParams.get('storeName').toLowerCase())[0])
            }
        }
    }, [window.location.href, filterResults])

    // useEffect(() => {
    //     if(new URL(window.location.href).searchParams.get('productName')){
    //         handleFilter('product', new URL(window.location.href).searchParams.get('productName'));
    //         setMenuExpanded('product');
    //     }
    // }, [window.location.href])
    const currentSite = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_CURRENT_SITE : '';


    return (
        <>
            <div className="sidebar-list">
                <div className="sidebar-list-item" onClick={handleNearMeLocation} style={{ color: themePallet.darkFont, borderBottom: "1px dotted " + themePallet.darkBackground }}>
                    <span>Product Locator</span>
                    <span className="near-me-location" >Near Me <img src={imageSelection[currentSite]}
                        alt="" /></span>
                </div>
                {/* <Accordion key={'Location'} sx={{ width: "280px", background: themePallet.lightBackground, color: themePallet.darkFont }}
                    expanded={expanded === 'location'}
                    onChange={handleChange('location')}
                    className="main-menu"
                    style={{ padding: "0 !important", borderBottom: "1px dashed " + themePallet.darkBackground }}
                >
                    <AccordionSummary
                        expandIcon={expanded === 'location' ?
                            <RemoveIcon style={{ color: themePallet.active }} /> :
                            <AddIcon style={{ color: themePallet.darkFont }} />}
                        aria-controls={"panel1a-content" + 'location'}
                        id={"panel1a-header" + 'location'}
                        sx={{ padding: "0 5px" }}
                    >
                        <Typography sx={{
                            fontSize: "13px !important",
                            fontWeight: "bold"
                        }}>Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ width: "250px", p: 0 }}
                        className="sidebar-menu-accordion"> */}

                {/* province */}
                {/* <Accordion
                            key={'main-province'}
                            sx={{
                                width: "280px",
                                background: themePallet.lightBackground,
                                color: themePallet.darkFont
                            }}
                            expanded={menuExpanded === 'province'}
                            onChange={handleSubMenuChange('province')}
                            className="sub-menu"
                        >
                            <AccordionSummary
                                aria-controls={"panel1a-content" + 'province'}
                                id={"panel1a-header" + 'province'}
                                sx={{ padding: "0 5px" }}
                                className="sub-menu-detail"
                            >
                                {menuExpanded === 'province' ?
                                    <RemoveIcon style={{
                                        color: themePallet.active,
                                        width: "14px"
                                    }} /> :
                                    <AddIcon style={{
                                        color: themePallet.darkFont,
                                        width: "14px"
                                    }} />}
                                <Typography
                                    sx={{ fontSize: "13px !important" }}>Province</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={'province'}
                                className={getAccordionData('province').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                                {
                                    getAccordionData('province').map((opt, index) => {
                                        return (
                                            <div key={opt.prov + index}
                                                className="filter-sub-menu" onClick={() => handleFilter('province', opt.prov)}>
                                                <span
                                                    className={"box"}
                                                    style={{ background: themePallet.lightBackground }}
                                                >
                                                    {isSelected(opt.prov) && <span
                                                        className="checked" style={{ background: themePallet.active }} />}
                                                </span>
                                                <Tooltip
                                                    title={opt.prov}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px !important" }} className="filter-name"

                                                    >{opt.prov}</Typography>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion> */}

                {/* city */}
                {/* <Accordion
                            key={'main-city'}
                            sx={{
                                width: "280px",
                                background: themePallet.lightBackground,
                                color: themePallet.darkFont
                            }}
                            expanded={menuExpanded === 'city'}
                            onChange={handleSubMenuChange('city')}
                            className="sub-menu"
                        >
                            <AccordionSummary
                                aria-controls={"panel1a-content" + 'city'}
                                id={"panel1a-header" + 'city'}
                                sx={{ padding: "0 5px" }}
                                className="sub-menu-detail"
                            >
                                {menuExpanded === 'city' ?
                                    <RemoveIcon style={{
                                        color: themePallet.active,
                                        width: "14px"
                                    }} /> :
                                    <AddIcon style={{
                                        color: themePallet.darkFont,
                                        width: "14px"
                                    }} />}
                                <Typography
                                    sx={{ fontSize: "13px !important" }}>City</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={'city'}
                                className={getAccordionData('city').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                                {
                                    getFilter('province').length > 0 &&
                                    <div className="parent-selected">
                                        <div className="selected-name">{getFilter('province')[0].name}</div>
                                        <div className="selected-icon" onClick={() => handleDeleteAction(getFilter('province')[0])}><CloseIcon style={{ color: themePallet.darkFont, width: "15px", height: "15px" }} /></div>
                                    </div>
                                }
                                {
                                    getAccordionData('city').map((opt, index) => {
                                        let province = getFilter('province');
                                        if (province.length > 0 && province[0].name != opt.prov) return <></>
                                        return (
                                            <div key={opt.city + index}
                                                className="filter-sub-menu" onClick={() => handleFilter('city', opt.city)}>
                                                <span
                                                    className={"box"}
                                                    style={{ background: themePallet.lightBackground }}
                                                >
                                                    {isSelected(opt.city) && <span
                                                        className="checked" style={{ background: themePallet.active }} />}
                                                </span>
                                                <Tooltip
                                                    title={opt.prov}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px !important" }} className="filter-name"

                                                    >{opt.city}</Typography>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion> */}

                {/* products filter section */}
                <Accordion
                    key={'product-product'}
                    sx={{
                        width: "280px",
                        background: themePallet.lightBackground,
                        color: themePallet.darkFont
                    }}
                    expanded={menuExpanded === 'product'}
                    onChange={handleSubMenuChange('product')}
                    className="sub-menu"
                >
                    <AccordionSummary
                        expandIcon={menuExpanded === 'product' ?
                            <RemoveIcon style={{ color: themePallet.active }} /> :
                            <AddIcon style={{ color: themePallet.darkFont }} />}
                        aria-controls={"panel1a-content" + 'product'}
                        id={"panel1a-header" + 'product'}
                        sx={{ padding: "0 5px" }}
                    >
                        <Typography sx={{
                            fontSize: "13px !important",
                            fontWeight: "bold"
                        }}>Products</Typography>
                    </AccordionSummary>
                    <AccordionDetails key={'product'}
                        className={getAccordionData('product').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                        {
                            getAccordionData('product').map((opt, index) => {
                                return (
                                    <div key={opt.name + index}
                                        className="filter-sub-menu" onClick={() => handleFilter('product', opt.name)}>
                                        <span
                                            className={"box"}
                                            style={{ background: themePallet.lightBackground }}
                                        >
                                            {isSelected(opt.name) && <span
                                                className="checked" style={{ background: themePallet.active }} />}
                                        </span>
                                        <Tooltip
                                            title={opt.name}
                                            placement="right"
                                            arrow
                                        >
                                            <Typography
                                                sx={{ fontSize: "13px !important" }} className="filter-name"
                                            >{opt.name}</Typography>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </AccordionDetails>
                </Accordion>

                {/* <Accordion key={'main-Products'} sx={{ width: "280px", background: themePallet.lightBackground, color: themePallet.darkFont }}
                    expanded={expanded === 'products'}
                    onChange={handleChange('products')}
                    className="main-menu"
                    style={{ padding: "0 !important", borderBottom: "1px dashed " + themePallet.darkBackground }}
                >
                    <AccordionSummary
                        expandIcon={expanded === 'products' ?
                            <RemoveIcon style={{ color: themePallet.active }} /> :
                            <AddIcon style={{ color: themePallet.darkFont }} />}
                        aria-controls={"panel1a-content" + 'products'}
                        id={"panel1a-header" + 'products'}
                        sx={{ padding: "0 5px" }}
                    >
                        <Typography sx={{
                            fontSize: "13px !important",
                            fontWeight: "bold"
                        }}>Products</Typography>
                    </AccordionSummary> */}
                {/* <AccordionDetails sx={{ width: "250px", p: 0 }}
                        className="sidebar-menu-accordion"> */}

                {/* category section */}
                {/* <Accordion
                            key={'category'}
                            sx={{
                                width: "280px",
                                background: themePallet.lightBackground,
                                color: themePallet.darkFont
                            }}
                            expanded={menuExpanded === 'category'}
                            onChange={handleSubMenuChange('category')}
                            className="sub-menu"
                        >
                            <AccordionSummary
                                aria-controls={"panel1a-content" + 'category'}
                                id={"panel1a-header" + 'category'}
                                sx={{ padding: "0 5px" }}
                                className="sub-menu-detail"
                            >
                                {menuExpanded === 'category' ?
                                    <RemoveIcon style={{
                                        color: themePallet.active,
                                        width: "14px"
                                    }} /> :
                                    <AddIcon style={{
                                        color: themePallet.darkFont,
                                        width: "14px"
                                    }} />}
                                <Typography
                                    sx={{ fontSize: "13px !important" }}>Category</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={'category'}
                                className={getAccordionData('category').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                                {
                                    getFilter('category').length > 0 &&
                                    <div className="parent-selected">
                                        <div className="selected-name">{getFilter('category')[0].category}</div>
                                        <div className="selected-icon" onClick={() => handleDeleteAction(getFilter('category')[0])}><CloseIcon style={{ color: themePallet.darkFont, width: "15px", height: "15px" }} /></div>
                                    </div>
                                }
                                {
                                    getAccordionData('category').map((opt, index) => {
                                        return (
                                            <div key={opt.category + index}
                                                className="filter-sub-menu" onClick={() => handleFilter("category", opt.category)}>
                                                <span
                                                    className={"box"}
                                                    style={{ background: themePallet.lightBackground }}
                                                >
                                                    {isSelected(opt.category) && <span
                                                        className="checked" style={{ background: themePallet.active }} />}
                                                </span>
                                                <Tooltip
                                                    title={opt.category}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px !important" }} className="filter-name"

                                                    >{opt.category}</Typography>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion> */}

                {/* Brand secction */}
                {/* <Accordion
                            key={'main-brand'}
                            sx={{
                                width: "280px",
                                background: themePallet.lightBackground,
                                color: themePallet.darkFont
                            }}
                            expanded={menuExpanded === 'brand'}
                            onChange={handleSubMenuChange('brand')}
                            className="sub-menu"
                        >
                            <AccordionSummary
                                aria-controls={"panel1a-content" + 'brand'}
                                id={"panel1a-header" + 'brand'}
                                sx={{ padding: "0 5px" }}
                                className="sub-menu-detail"
                            >
                                {menuExpanded === 'brand' ?
                                    <RemoveIcon style={{
                                        color: themePallet.active,
                                        width: "14px"
                                    }} /> :
                                    <AddIcon style={{
                                        color: themePallet.darkFont,
                                        width: "14px"
                                    }} />}
                                <Typography
                                    sx={{ fontSize: "13px !important" }}>Brand</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={'brand'}
                                className={getAccordionData('brand').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                                {
                                    getAccordionData('brand').map((opt, index) => {
                                        return (
                                            <div key={opt + index}
                                                className="filter-sub-menu" onClick={() => handleFilter("brand", opt)}>
                                                <span
                                                    className={"box"}
                                                    style={{ background: themePallet.lightBackground }}
                                                >
                                                    {isSelected(opt) && <span
                                                        className="checked" style={{ background: themePallet.active }} />}
                                                </span>
                                                <Tooltip
                                                    title={opt}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px !important" }} className="filter-name"

                                                    >{opt}</Typography>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion> */}

                {/* products section */}
                {/* <Accordion
                            key={'product-product'}
                            sx={{
                                width: "280px",
                                background: themePallet.lightBackground,
                                color: themePallet.darkFont
                            }}
                            expanded={menuExpanded === 'product'}
                            onChange={handleSubMenuChange('product')}
                            className="sub-menu"
                        >
                            <AccordionSummary
                                aria-controls={"panel1a-content" + 'product'}
                                id={"panel1a-header" + 'product'}
                                sx={{ padding: "0 5px" }}
                                className="sub-menu-detail"
                            >
                                {menuExpanded === 'product' ?
                                    <RemoveIcon style={{
                                        color: themePallet.active,
                                        width: "14px"
                                    }} /> :
                                    <AddIcon style={{
                                        color: themePallet.darkFont,
                                        width: "14px"
                                    }} />}
                                <Typography
                                    sx={{ fontSize: "13px !important" }}>Product</Typography>
                            </AccordionSummary>
                            <AccordionDetails key={'product'}
                                className={getAccordionData('product').length === 0 ? "height-0 filter-sub-menu-accordion" : "filter-sub-menu-accordion"}>
                                {
                                    getAccordionData('product').map((opt, index) => {
                                        return (
                                            <div key={opt.name + index}
                                                className="filter-sub-menu" onClick={() => handleFilter('product', opt.name)}>
                                                <span
                                                    className={"box"}
                                                    style={{ background: themePallet.lightBackground }}
                                                >
                                                    {isSelected(opt.name) && <span
                                                        className="checked" style={{ background: themePallet.active }} />}
                                                </span>
                                                <Tooltip
                                                    title={opt.name}
                                                    placement="right"
                                                    arrow
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "13px !important" }} className="filter-name"

                                                    >{opt.name}</Typography>
                                                </Tooltip>
                                            </div>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion> */}
                {/* </AccordionDetails> */}
                {/* </Accordion> */}
            </div>
            <div className="result-section">
                {
                    typeof filterResults === 'string' ?
                    <div className="no-results-message">No product found</div>
                    :
                    filterResults && filterResults.map(item => {
                        let inStockStatus = item.stockStatus
                        return (
                            <div className="result-item" onClick={() => handleDetailPage(item)} style={{ borderBottom: "2px solid " + themePallet.darkBackground }}>
                                <div className="result-header">
                                    <div
                                        className={"location-name "}>
                                        {inStockStatus === "In Stock" && <span className="active-location" style={{ border: "1px solid " + themePallet.active, background: themePallet.active }} />}
                                        <Tooltip
                                            title={item.name}
                                            placement="top"
                                            arrow
                                        >
                                            <div className="text" style={{ color: inStockStatus === 'In Stock' ? themePallet.darkFont : themePallet.lightFont }}>{item.name}</div>
                                        </Tooltip>
                                    </div>
                                    <div
                                        className={"product-status"}
                                        style={{ color: inStockStatus === 'In Stock' ? themePallet.stockColor : themePallet.lightFont }}
                                    >{item.stockStatus}</div>
                                </div>
                                <div className="result-address">

                                    <div
                                        className="address"
                                        style={{ color: inStockStatus === 'In Stock' ? themePallet.darkFont : themePallet.lightFont }}
                                    >{item.address + ", " + item?.city + ", " + item.province}</div>
                                    <div
                                        className={inStockStatus === "In Stock" ? "distance bright-distance" : "distance"}
                                        style={{ color: inStockStatus === 'In Stock' ? themePallet.darkFont : themePallet.lightFont }}
                                        >{(parseFloat(item.distance).toFixed(1) ?? 0)} KM
                                    </div>
                                </div>
                                <div className="timing"
                                    style={{ color: themePallet.lightFont }}
                                >
                                    {item.hours}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default FilterSidebar;
