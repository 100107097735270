import axios from 'axios'
import { setLoader, setTab } from './mainAction';

export const SET_FILTERS = "SET_FILTERS";
export const SET_FILTER_RESULTS = "SET_FILTER_RESULTS";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
export const SET_SEARCH_TAG = "SET_SEARCH_TAG";

export const setFilters = (data) => {
    return {
        type: SET_FILTERS,
        payload: data
    }
}

export const setFilterResults = (data) => {
    return {
        type: SET_FILTER_RESULTS,
        payload: data
    }
}

export const setSelectedLocation = (data) => {
    return {
        type: SET_SELECTED_LOCATION,
        payload: data
    }
}

export const setSelectedFilter = (data) => {
    return {
        type: SET_SELECTED_FILTER,
        payload: data
    }
}

export const setSearchingTag = (data) => {
    return {
        type: SET_SEARCH_TAG,
        payload: data
    }
}

export const getFilteredData = (payload) => {
    return (dispatch) => {
        try {
            const brand = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : '';

            axios.get(`${process.env.REACT_APP_API}MapLocations/GetLocationsWithFilters?brand_id=${brand}&${payload}`, {
                headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
            })
                .then(resp => {
                    dispatch(setFilterResults(resp.data));
                    dispatch(setLoader(false));
                })
                .catch(error => {
                    dispatch(setLoader(false));
                })
        }
        catch (e) {
            dispatch(setLoader(false));
            console.log(e)
        }
    }
}

export const getSingleLocationData = (payload, type = null) => {
    return (dispatch) => {
        try {
            const brand = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_BRAND : '';
            axios.get(`${process.env.REACT_APP_API}MapLocations/GetLocationsDetails?brand_id=${brand}&${payload}`, {
                headers: { 'Content-Type': 'application/json', "Ocp-Apim-Subscription-Key": process.env.REACT_APP_OCPAPIMSUBKEY },
            })
                .then(resp => {
                    if (type === null) dispatch(setLoader(false));
                    dispatch(setTab({ id: "location-detail", detail: { ...resp.data[0], ...(type && { type }) } }))
                })
                .catch(error => {
                    dispatch(setLoader(false));
                })
        } catch (error) {
            console.log(error)
        }
    }
}
