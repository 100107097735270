import { combineReducers } from "redux";

import main from "./mainReducer";
import filters from "./filterReducer";

const rootReducer = combineReducers({
    main,
    filters
});

export default rootReducer;