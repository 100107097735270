import {SET_FILTER_RESULTS, SET_FILTERS, SET_SELECTED_FILTER, SET_SELECTED_LOCATION, SET_SEARCH_TAG} from "../actions/filterAction";

const initialState = {
    filter: [],
    selectedFilter: [],
    filterResults: [],
    selectedLocation: null,
    search: [
        {
            id: "province",
            province: []
        },
        {
            id: "city",
            city: []
        },
        {
            id: "category",
            category: []
        },
        {
            id: "brand",
            brand: []
        },
        {
            id: "product",
            product: []
        }
    ]
};

const filter = (state = initialState, action) => {
    const {type, payload} = action
    let newState = JSON.parse(JSON.stringify(state))

    switch (type) {
        case SET_FILTERS:
            newState.filter = payload
            return newState
        case SET_FILTER_RESULTS:
            newState.filterResults = payload
            return newState
        case SET_SELECTED_LOCATION:
            newState.selectedLocation = payload
            return newState
        case SET_SELECTED_FILTER:
            newState.selectedFilter = payload
            return newState
        case SET_SEARCH_TAG:
            newState.search = payload
            return newState
        default:
            return state
    }
};

export default filter;
