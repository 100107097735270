import React, { useState, useEffect } from "react";

import "./sliderforzoom.css";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Logo from '../../images/logo.png'

const useStyles = makeStyles({
  root: {
    height: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto"
  },
});

const SliderForZoom = (props) => {
  const [value, setValue] = useState(13);
  const classes = useStyles();
  const handleHover = () => {
    props.setMapDragging(false);
  };
  const handleLeave = () => {
    props.setMapDragging(true);
  };
  useEffect(() => {
    setValue(props.zoom);
  }, [props.zoom]);
  const poweredBy = localStorage.getItem('MapSettings') ? JSON.parse(localStorage.getItem('MapSettings')).REACT_APP_NEOBI_POWERED_BY : '';

  return (
    <React.Fragment>
      {/* <div className="slider-Zoom">
        <div className="navbar-vaccum"></div>
        <div
          className={classes.root}
          onMouseLeave={handleLeave}
          onMouseEnter={handleHover}
        >
          <div className="minus-icon-wrapper">
            <AddIcon
              style={{ cursor: "pointer" }}
              onClick={() => props.plusMinusZoom("plus")}
            />
          </div>
          <Slider
            orientation="vertical"
            onChange={props.setMapZoom}
            defaultValue={value}
            value={props.zoom}
            disabled={props.zoom < 1 || props.zoom > 18}
            min={2}
            max={20}
            step={1}
            className={process.env.REACT_APP_CURRENT_SITE}
          />
          <div className="plus-icon-wrapper">
            <RemoveIcon
              style={{ cursor: "pointer" }}
              onClick={() => props.plusMinusZoom("minus")}
            />
          </div>
          </div> 
        <div className="logo">
          <a href="https://neobi.io" target="_blank">
            <img src={process.env.REACT_APP_NEOBI_POWERED_BY} alt="" />
          </a>
        </div>
      </div>*/}
      <div className="logo">
        <a href="https://neobi.io" target="_blank">
          <img src={poweredBy} alt="text" />
        </a>
      </div>
    </React.Fragment>
  );
};

export default SliderForZoom;
